<!-- src/views/widget/Widget.vue -->
<template>
    <div class="widget-container">
        <h2 class="sr-only" id="frameResponse"></h2>
        <div id="arcopayContainer"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import store from "@/store";
import { useRoute } from 'vue-router'

export default {
    name: "AfterBankWidget",
    props: {
    },
    setup() {
        const route = useRoute();
        return {
            route
        }
    },
    data() {
        return {
            banks: {},
            parameters: {
                id: null,
                action: "read",
                countryCode: "ALL",
                banksShownPSD2: "",
                banksShownV3: "",
                defaultBank: "",
                autoStart: 0,
                defaultLanguage: "ES",
                showSandbox: 0,
                showSplashScreen: 0,
                showCheckTerms: 0,
                URLredirectAfterOK: `${window.location.origin}/pre-aprobado/paso-2`,
                // URLredirectAfterKO: `${window.location.origin}/pre-aprobado/paso-2`,
                URLredirectAfterKO: '',
                dailyFrequency: 4,
                validUntil: this.calcularFechaProxima()
            },
        };
    },
    store,
    computed: {
        ...mapState({
            sessionPreAprobado: (state) => state.sessionPreAprobado,
        }),
    },
    methods: {
        calcularFechaProxima() {
            const hoy = new Date();
            // Sumar un mes
            hoy.setMonth(hoy.getMonth() + 1);
            // Formatear la fecha en DD-MM-YYYY con guiones
            const dia = hoy.getDate().toString().padStart(2, '0');
            const mes = (hoy.getMonth() + 1).toString().padStart(2, '0'); // Los meses son de 0 a 11
            const anio = hoy.getFullYear();

            const fechaFormateada = `${dia}-${mes}-${anio}`;
            return fechaFormateada;
        },
        async getBanks() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_BASE_URL}getServiceAfterbanks`);
                return response.data || null;
            } catch (error) {
                console.error("Error al obtener bancos:", error);
                return null;
            }
        },
        async initializeWidget() {
            try {
                const banksCollection = await this.getBanks();
                if (!banksCollection) {
                    throw new Error("No se encontraron bancos");
                }
                this.banks = {
                    PSD2: banksCollection.bancosPSD2.join(","),
                    V3: banksCollection.bancosV3.join(",")
                };
                this.parameters.banksShownPSD2 = this.banks.PSD2;
                this.parameters.banksShownV3 = this.banks.V3;
            } catch (error) {
                console.error("Error en la inicialización:", error);
                this.$router.push({ path: "/" });
            }
        },
        handleFrameMessage(ev) {
            if (!ev.data?.code || !ev.data?.message) return;

            const { code, message } = ev.data;
            const widgetContainer = document.getElementById('arcopayContainer');
            const frameResponse = document.getElementById('frameResponse');
            
            console.warn(`${code} - ${message}`);

            switch (code) {
                case 200:
                    if (window.parameters.action === 'read') {
                        frameResponse.innerHTML = 'Lectura finalizada correctamente.';
                    }
                    break;
                case 9999:
                    widgetContainer.style.height = `${message}px`;
                    window.scrollTo(0, 0);
                    document.getElementById('iframeArcopay')?.contentWindow.postMessage(
                        { action: "changeIframeHeight" },
                        'https://www.afterbanks.com/'
                    );
                    break;
                default:
                    frameResponse.innerHTML = `${code} - ${message}`;
                    break;
            }
        }
    },
    async mounted() {
        await this.initializeWidget();
        window.parameters = {
            ...this.parameters,
            id: this.sessionPreAprobado.id_solicitud
        };

        const script = document.createElement('script');
        script.src = "https://static.afterbanks.com/appmain/PSD2ExternalForm/js/external_mixed.js";
        document.head.appendChild(script);

        window.addEventListener("message", this.handleFrameMessage, false);
    },
    unmounted() {
        window.removeEventListener("message", this.handleFrameMessage);
    }
};
</script>

<style scoped>
.widget-container {
    margin-top: 5rem;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
</style>